import {dateFormat} from "@/util/date";

export default {
  methods:{
    formatterData(jsonList){
      return jsonList.filter(item => item.isObject !== 1).map(item => {
        item = Object.assign({}, item)
        if (item.children) {
          item.children = this.formatterData(item.children)
        }
        return item
      })
    },
  },
  computed: {
    corePersonnelTableOption(){
      let column=[{
        label: "检查内容",
        prop: "indexName",
        align: "left",
        overHidden: true
      },
        {
          label: "检查人",
          prop: "respNickName",
          width: 200,
          align: "center",
          overHidden: true,
        },

      ]
      if(this.formType=='view' && this.dataForm.actStatus == 'FINISHED'){
        column.push({
          label: "检查结果",
          prop: "lineStatus",
          width: 200,
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=HD_CHECK_INDEX_STATUS`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },

          type: 'select',
          align: "center",
          overHidden: true,
        },)
      }
      return {
        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        column: column,
      }
    },
    jcxOption(){
      let that=this
      return {
        span:24,
        labelWidth:100,
        emptyText:'取消',
        column: [
          {
            span:12,
            label: "检查人",
            prop: "respUserName",
            type:'select',
            dicUrl:`/api/sinoma-hse-prj/hdcheckperson/list?actId=${this.dataForm.id}&=current=1&size=9999`,
            dicFormatter:(res)=>{
              that.respUserList=res.data.records
              return res.data.records
            },
            change({column,value}){
              if(!value){
                that.$set(that.jcxForm, 'respUserName', '')
                that.$set(that.jcxForm, 'respNickName', '')
              }else {
                let {userName,userNickName,userAccount}=that.respUserList.filter(item=>item.userName == value)[0]
                that.$set(that.jcxForm, 'respUserName', userName)
                that.$set(that.jcxForm, 'respNickName', userNickName)
                that.$set(that.jcxForm, 'respUserAccount', userAccount)
              }
            },
            props: {
              label: 'userNickName',
              value: 'userName',
            },

          },
          {
            span:12,
            value:'1',
            disabled:true,
            label: "检查对象",
            prop: "objectName",
            rules: [{
              required: true,
              message: "请输入检查对象",
              trigger: "blur"
            }]
          },
          {
            label: "检查内容",
            prop: "indexName",
            type:'textarea',
            maxlength:500,
            showWordLimit:true,
            minRows:3,
            rules: [{
              required: true,
              message: "请输入检查内容",
              trigger: "blur"
            }]
          }
        ]
      }
    } ,
    fileBtnOptions(){
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "新增",
          emit: "headImport",
          type: "button",
          btnOptType: "upload",
        });
      }
      return result
    },
    userBtnOptions(){
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "新增",
          emit: "head-add",
          type: "button",
          btnOptType: "add",
        });
        result.push({
          label: "移除",
          emit: "head-remove",
          type: "button",
          btnOptType: "remove",
        });
      }
      return result
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.formType) && this.dataForm.actStatus != 'CONFIRMED') {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
        if(this.dataForm.id){
          result.push({
            label: "保存并返回",
            emit: "head-saveBack",
            type: "button",
            btnOptType: "refer",
          });
        }
      }
      if(this.dataForm.actStatus == 'CONFIRMED'){
        result.push({
          label: "完成",
          emit: "head-finished",
          type: "button",
          btnOptType: "complete",
        });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
    jcxflOption() {
     return {
       span:24,
       submitBtn:(this.jcxflForm.isObject == 1 && this.jcxflForm.objectType == 'SD') || this.jcxflForm.id ? true : this.jcxflForm.isObject == 0 ? true : false ,
       emptyText:'取消',
       emptyIcon:'x',
       submitIcon :'x',
       column: [
         {
           span:24,
           labelWidth:200,
           label: '类型',
           prop: 'isObject',
           type: 'radio',
           dicData:[{
             label:'检查对象分类',
             value:0
           },{
             label:'检查对象',
             value:1
           }]
         },
         {
           span:24,
           props:{
             children: "children",
             label: "typeName",
             value:'id',
           },
           dicData:this.corePersonnelTreeData,
           type: 'tree',
           labelWidth:200,
           clearable:true,
           label: "上级检查项分类",
           prop: "parentId",
         },
         {
           span:24,
           display:this.jcxflForm.isObject == 0,
           labelWidth:200,
           label: "分类名称",
           prop: "typeName",
           rules: [{
             required: true,
             message: "请输入分类名称",
             trigger: "blur"
           }]
         },
         {
           span:12,
           disabled:this.jcxflForm.id ?true :false,
           display:this.jcxflForm.isObject == 1,
           labelWidth:200,
           label: "对象类型",
           type: 'select',
           change:()=>{
             if(this.jcxflForm.id)return
             this.jcxflForm.typeName=''
         },
           dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=HD_CHECK_OBJ_TYPE',
           props: {
             label: 'dictValue',
             value: 'dictKey',
           },
           prop: "objectType",
           rules: [{
             required: true,
             message: "请选择对象类型",
             trigger: "blur"
           }]
         },
         {
           span:12,
           formSlot:true,
           display:this.jcxflForm.isObject == 1,
           labelWidth:this.jcxflForm.objectType == 'SD'  ?100 : 0,
           label: this.jcxflForm.objectType == 'SD' ?"对象名称" :'',
           prop: "typeName",
           rules: [{
             required: this.jcxflForm.objectType == 'SD' ? true : false,
             message: "请输入对象名称",
             trigger: "blur"
           }]
         },

       ]
     }
    },
    excelOption(){
      // if(!this.selectTree)return
     return  {
       submitBtn: false,
       emptyBtn: false,
       excelForm: {},
       column: [{
         label: '数据导入',
         prop: 'excelFile',
         type: 'upload',
         drag: true,
         loadText: this.$t("cip.plat.sys.user.field.waiting"),
         span: 24,
         propsHttp: {
           res: 'data'
         },
         tip: this.$t("cip.plat.sys.user.field.format"),
         action: `/api/sinoma-hse-prj/hdcheckindex/import?actId=${this.dataForm.id}`
       },
         {
           label: this.$t("cip.plat.sys.user.field.excelTemplate"),
           prop: 'excelTemplate',
           formslot: true,
           span: 24,
         }
       ]
     }
    },
  },
  data(){
    let that=this;
    return {
      hdchecktaskproOption: {
        index: true,
        indexLabel: "序号",
        linklabel: "hdCode",
        menuWidth: 100,
        column: [{
          label: "问题编号",
          prop: "hdCode",
          align: "left",
          overHidden: true
        },
          {
            label: "问题描述",
            prop: "hdDesc",
            width: 200,
            align: "center",
            overHidden: true,
          }, {
            label: "问题类型",
            prop: "proType",
            width: 200,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=HD_PRO_TYPE`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            type: 'select',
            align: "center",
            overHidden: true,
          }, {
            label: "上报人",
            prop: "reportUserName",
            width: 200,
            align: "center",
            overHidden: true,
          }, {
            label: "上报人部门",
            prop: "reportDeptName",
            width: 200,
            align: "center",
            overHidden: true,
          }, {
            label: "上报时间",
            prop: "reportDate",
            width: 200,
            align: "center",
            overHidden: true,
          }, {
            label: "状态",
            prop: "hdStatus",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            width: 200,
            align: "center",
            overHidden: true,
          },

        ],
      },
      corePersonnelHeadBtnOptions: [
        {label: "新增", emit: "head-add", type: "button", icon: ""},
        {label: "导入", emit: "handleImport", type: "button", icon: ""},
        {label: "导出", emit: "head-export", type: "button", icon: ""},
        // {label: "风险库", emit: "head-fx", type: "button", icon: ""},
        {label: "移除", emit: "head-del", type: "button", icon: ""},
      ],
      formType: '',
      mapBox: false,
      mapdata: '',
      dataForm: {
        isAutomatic: true,
        actCode:'',
        checkTime:dateFormat(new Date()),
      },
      tableData: [],
      corePersonnelTableData: [],
      tableLoading: false,
      jcxTableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      jcryPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      defaultProps: {
        children: "children",
        label: "typeName",
      },
      organizationPersonnelTreeData: [],
      searchOrTitle:"structureName",
      activeName: 'thirdPartyOrganization',
      corePersonnelTreeData: [],
       searchColumns: [
        {placeholder: '请输入风险名称', prop: "riskCode", span: 4},
        {placeholder: '请输入措施分类', prop: "riskCode", span: 4},
        {placeholder: '请输入措施描述', prop: "riskCode", span: 4},
        {placeholder: '请输入隐患排查内容', prop: "riskCode", span: 4},
        {placeholder: '请选择管控层级', prop: "riskCode", span: 4},
        {placeholder: '请输入岗位/角色', prop: "riskCode", span: 4},
        {placeholder: '请输入负责人员', prop: "riskCode", span: 4},

      ],
      fxyOption: {
        index: true,
        menu: false,
        reserveSelection:true,
        indexLabel: "序号",
        menuWidth: 100,
        linklabel: "title",
        column: [{
          label: "风险名称",
          prop: "riskName",
          align: "left",
          overHidden: true
        },
          {
            label: "措施分类",
            prop: "treatmentTypeName",
            width: 200,
            align: "center",
            overHidden: true
          },
          {
            label: "措施描述",
            prop: "treatmentDesc",
            width: 200,
            align: "center",
            overHidden: true
          },
          {
            label: "隐患排查内容",
            prop: "hdCheckContext",
            width: 150,
            align: "center",
            overHidden: true
          },
          {
            label: "管控层级",
            prop: "controlLevel",
            width: 150,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=risk_control_level`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            type:'select',
            align: "center",
            overHidden: true
          },  {
            label: "岗位/角色",
            prop: "respPostName",
            width: 150,
            align: "center",
            formatter:(row)=>{
              return `${ row.respPostName+'/'}${row.respRoleName}`
            },
            overHidden: true
          }, {
            label: "负责人员",
            prop: "respUserName",
            width: 150,
            align: "center",
            overHidden: true
          },

        ],
      },
      thirdPartyOrganizationTableOption: {
        index: true,
        height:200,
        indexLabel: "序号",
        menuWidth: 100,
        linklabel: "title",
        column: [{
          label: "人员名称",
          prop: "userNickName",
          align: "left",
          width: 120,
          overHidden: true
        },
          {
            label: "所在组织部门",
            prop: "deptName",
            align: "left",
            overHidden: true
          },
          {
            label: "角色",
            prop: "roleName",
            align: "left",
            overHidden: true
          },
          {
            label: "岗位",
            prop: "respPostName",
            width: 150,
            align: "center",
            overHidden: true
          },
 {
            label: "检查任务执行情况",
            prop: "taskStatusName",
            width: 150,
            align: "center",
            overHidden: true
          },

        ],
      },
      jcryTableLoading:false,
      thirdPartyOrganizationTableData: [],
      thirdPartyPersonnelTableData: [],
      rules: {
        actName: [{
          required: true,
          message: '请输入检查名称',
          trigger: 'blur'
        }],
        checkType: [{
          required: true,
          message: '请选择检查类型',
          trigger: 'change'
        }],
        actCode: [{
          required: true,
          message: '请输入检查编号',
          trigger: 'blur'
        }],
        startUserName: [{
          required: true,
          message: '请选择发起人',
          trigger: 'change'
        }],
        organizationName: [{
          required: true,
          message: '请选择所属组织',
          trigger: 'change'
        }],
        checkTime: [{
          required: true,
          message: '请选择检查时间',
          trigger: 'change'
        }],

      },
      prjDomesticList: [],
      selectionList: [],
    }
  },
}
