<template>
  <div style="height: 100%; background-color: #ffffff">
    <head-layout
      :head-title="headTitle"
      :head-btn-options="headBtnOptions"
      @head-save="headSave(false, 'PREPARE')"
      @head-finished="headSave(true, 'FINISHED')"
      @head-saveBack="headSave(true, 'CONFIRMED')"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain" style="padding: 0 12px">
        <el-form
          ref="dataForm"
          :model="dataForm"
          label-width="150px"
          :disabled="formType == 'view' || dataForm.actStatus == 'CONFIRMED'"
          :rules="rules"
        >
          <!-- <div class="formTopic" style="margin-bottom: 12px">检查基本信息</div> -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="检查编号" prop="actCode">
                <el-input
                  v-model="dataForm.actCode"
                  :disabled="
                    dataForm.isAutomatic || pageDisabled || dataForm.id
                  "
                  placeholder="请输入检查编号"
                >
                  <template slot="append">
                    自动生成
                    <el-switch
                      :disabled="pageDisabled || dataForm.id"
                      v-model="dataForm.isAutomatic"
                      active-color="#13ce66"
                      @change="handleSwitch"
                    >
                    </el-switch>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="检查名称" prop="actName">
                <el-input
                  v-model="dataForm.actName"
                  placeholder="请输入检查名称"
                  :title="dataForm.actName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="检查类型" prop="checkType">
                <el-select
                  v-model="dataForm.checkType"
                  placeholder="请选择检查类型"
                  disabled
                >
                  <el-option
                    v-for="item in check_type"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="发起人" prop="startUserName">
                <el-input
                  v-model="dataForm.startUserName"
                  @focus="headAdd('startUserName')"
                  placeholder="请选择发起人"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属组织" prop="organizationName">
                <el-input
                  v-model="dataForm.organizationName"
                  @focus="handleProjectOPen()"
                  :title="dataForm.organizationName"
                  placeholder="请填写所属组织"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="检查日期" prop="checkTime">
                <el-date-picker
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="dataForm.checkTime"
                  placeholder="请选择检查日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="dataForm.remark"
                  type="textarea"
                  :autosize="{ minRows: 2 }"
                  show-word-limit
                  maxlength="500"
                  placeholder="请填写备注"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="shuttleBackBox">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              label="检查人员"
              name="thirdPartyOrganization"
            ></el-tab-pane>
            <!-- <el-tab-pane label="检查项" name="corePersonnel"></el-tab-pane> -->
            <el-tab-pane
              label="相关资料 "
              name="thirdPartyPersonnel"
            ></el-tab-pane>
            <el-tab-pane
              label="问题清单"
              v-if="formType == 'view'"
              name="taskList"
            ></el-tab-pane>
            <el-tab-pane
              label="整改通知书"
              v-if="formType == 'view'"
              name="Rectify"
            ></el-tab-pane>
            <el-tab-pane
              label="处罚通知书"
              v-if="formType == 'view'"
              name="punishment"
            ></el-tab-pane>
          </el-tabs>
          <el-container class="main-box" v-if="activeName == 'corePersonnel'">
            <CommonTree
              treeTitle="检查项分类"
              :isShowdig="dataForm.actStatus == 'PREPARE'"
              node-key="id"
              isLeafIcons
              :defaultExpandedKeys="defaultExpandedKeys"
              iconsFlied="isObj"
              ref="commonTreeCore"
              @getTreeAdd="getTreeAdd"
              @getTreeDelete="getTreeDelete"
              @getTreeEdit="getTreeEdit"
              searchTitle="typeName"
              :treeData="corePersonnelTreeData"
              :defaultProps="defaultProps"
              @getNodeClick="corePersonnelTreeNodeClick"
              :showCheckbox="false"
              :treeExpand="true"
            />
            <div class="table-box">
              <head-layout
                v-if="formType != 'view'"
                :head-btn-options="
                  dataForm.actStatus == 'PREPARE'
                    ? corePersonnelHeadBtnOptions
                    : []
                "
                @head-export="headExport"
                @head-fx="headFx"
                @head-del="headDel"
                @handleImport="handleImport"
                @head-add="addjcx"
                head-title="检查项"
              ></head-layout>
              <grid-layout
                ref="jcxGridLayOut"
                v-if="dataForm.actStatus"
                :table-options="corePersonnelTableOption"
                :data-total="page.total"
                :page="page"
                :cell-style="cellStyle"
                @page-current-change="getJcx"
                @page-size-change="getJcx"
                :table-data="corePersonnelTableData"
                :table-loading="jcxTableLoading"
                @gird-handle-select-click="selectionChange"
              >
                <template #customBtn="{ row }">
                  <el-button
                    v-if="
                      formType == 'view' && dataForm.actStatus == 'FINISHED'
                    "
                    style="margin: 0 3px"
                    type="text"
                    size="small"
                    @click="zxjc(row)"
                    >查看
                  </el-button>
                  <el-button
                    v-if="formType != 'view' && dataForm.actStatus == 'PREPARE'"
                    style="margin: 0 3px"
                    type="text"
                    size="small"
                    @click="addjcx(row)"
                    >编辑
                  </el-button>
                  <el-button
                    v-if="formType != 'view' && dataForm.actStatus == 'PREPARE'"
                    style="margin: 0 3px"
                    type="text"
                    size="small"
                    @click="rowDel([row])"
                    >删除
                  </el-button>
                </template>
              </grid-layout>
            </div>
          </el-container>
          <div v-if="activeName == 'thirdPartyOrganization'">
            <head-layout
              v-if="formType != 'view'"
              head-title="检查人员"
              :head-btn-options="
                dataForm.actStatus == 'PREPARE' ? userBtnOptions : []
              "
              @head-add="headAdd1('jcry')"
              @head-remove="headRemove"
            ></head-layout>
            <grid-layout
              ref="gridLayOut1"
              :table-options="thirdPartyOrganizationTableOption"
              :data-total="jcryPage.total"
              :page="jcryPage"
              @page-current-change="getJcUser"
              @page-size-change="getJcUser"
              :table-data="thirdPartyOrganizationTableData"
              :table-loading="jcryTableLoading"
              @gird-handle-select-click="selectionUserChange"
            >
              <template
                #customBtn="{ row }"
                v-if="formType != 'view' && dataForm.actStatus == 'PREPARE'"
              >
                <el-button
                  style="margin: 0 3px"
                  type="text"
                  size="small"
                  @click="rowUserDel([row])"
                  >删除</el-button
                >
              </template>
            </grid-layout>
          </div>
          <div v-if="activeName == 'thirdPartyPersonnel'">
            <head-layout
              v-if="formType != 'view'"
              head-title="资料清单"
              :head-btn-options="
                dataForm.actStatus == 'PREPARE' ? fileBtnOptions : []
              "
              @headImport="headImport"
            >
              <el-button @click="quote()" size="mini" slot="right"
                >知识库选择</el-button
              >
            </head-layout>
            <el-upload
              :disabled="dataForm.actStatus != 'PREPARE' ? true : false"
              :before-remove="beforeRemove"
              :on-remove="handleRemove"
              :on-preview="handlePreview"
              :file-list="xgzlFileList"
              class="upload-demo"
            >
            </el-upload>
          </div>
          <div v-if="activeName == 'taskList'">
            <grid-layout
              ref="gridLayOut2"
              :table-options="hdchecktaskproOption"
              @grid-row-detail-click="rowView"
              :table-data="hdchecktaskproData"
              :table-loading="tableLoading"
              @gird-handle-select-click="selectionChange"
            >
              <template #customBtn="{ row }" v-if="formType == 'view'">
                <el-button
                  style="margin: 0 3px"
                  type="text"
                  size="small"
                  @click="rowView(row)"
                  >查看</el-button
                >
              </template>
            </grid-layout>
          </div>
          <el-upload
            v-if="activeName == 'Rectify'"
            disabled
            :on-preview="handlePreview1"
            :file-list="RectifyList"
            class="upload-demo"
          >
          </el-upload>
          <el-upload
            v-if="activeName == 'punishment'"
            disabled
            :on-preview="handlePreview1"
            :file-list="punishmentList"
            class="upload-demo"
          >
          </el-upload>
        </div>
      </div>
    </div>
    <el-upload
      v-show="hideUpload"
      accept=".doc,.docx,.pdf,.xlsx,.xls,.zip,.jpg,.png,.rar,.ppt,.pptx"
      class="upload-demo"
      action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
      :show-file-list="false"
      :on-success="handleSuccess"
      ref="uploadRef"
      :headers="headers"
    ></el-upload>
    <el-dialog
      title="人员选择"
      :visible.sync="deptShow"
      v-if="deptShow"
      width="80%"
      top="8vh"
    >
      <UserDetpDialog
        ref="UserDetpDialog"
        :dept-category="[2, 5]"
        @select-data="selectData"
      ></UserDetpDialog>
    </el-dialog>
    <el-dialog
      title="人员选择"
      :visible.sync="MultipShow"
      width="80%"
      top="8vh"
      v-if="MultipShow"
    >
      <UserDeptMultipleDialog
        @closeDia="MultipShow = false"
        ref="UserDeptMultipleDialog"
        @select-all-data="selectData"
      ></UserDeptMultipleDialog>
    </el-dialog>
    <el-dialog title="项目选择" :visible.sync="deptShow1" width="80%">
      <DeptDialog
        ref="DeptDialog"
        :deptCategory="[2, 5]"
        @select-data="selectData1"
      ></DeptDialog>
    </el-dialog>
    <el-dialog
      title="检查分类导入"
      v-if="excelBox"
      append-to-body
      :visible.sync="excelBox"
      width="555px"
    >
      <div class="import">
        <avue-form
          :option="excelOption"
          v-model="excelOption.excelForm"
          :upload-after="uploadAfter"
        >
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{ $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
    <el-dialog
      title="检查项"
      v-dialogDrag
      v-if="jcxModel"
      :visible.sync="jcxModel"
      width="40%"
    >
      <avue-form
        :option="jcxOption"
        v-if="jcxModel"
        @reset-change="resetChange1"
        @submit="handleSubmit1"
        v-model="jcxForm"
      ></avue-form>
    </el-dialog>
    <el-dialog
      title="检查对象及分类新增"
      v-if="jcxflModel"
      v-dialogDrag
      :visible.sync="jcxflModel"
      width="50%"
    >
      <avue-form
        :option="jcxflOption"
        @reset-change="resetChange"
        @submit="handleSubmit"
        v-model="jcxflForm"
      >
        <template #typeName>
          <el-input
            v-model="jcxflForm.typeName"
            v-if="
              jcxflForm.objectType == 'SD' ||
              jcxflForm.id ||
              jcxflForm.isObject == 0
            "
          ></el-input>
          <el-button
            @click="handleTable('EQ')"
            type="primary"
            v-if="
              jcxflForm.objectType == 'EQ' &&
              !jcxflForm.id &&
              jcxflForm.isObject == 1
            "
            >选择设备设施</el-button
          >
          <el-button
            type="primary"
            v-if="
              jcxflForm.objectType == 'AREA' &&
              !jcxflForm.id &&
              jcxflForm.isObject == 1
            "
            @click="handleTable('qy')"
            >选择区域</el-button
          >
          <el-button
            type="primary"
            v-if="
              jcxflForm.objectType == 'WORK' &&
              !jcxflForm.id &&
              jcxflForm.isObject == 1
            "
            @click="handleTable('ts')"
            >选择特殊作业</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
    <el-dialog
      title="风险库及措施"
      v-if="fxyModel"
      v-dialogDrag
      :visible.sync="fxyModel"
      width="80%"
      class="dangerBox"
    >
      <el-container style="height: 500px">
        <CommonTree
          ref="CommonTree"
          treeTitle="风险源"
          :defaultProps="{
            children: 'children',
            id: 'id',
            label: 'sourceTypeName',
          }"
          searchTitle="sourceTypeName"
          :showCheckbox="false"
          :treeData="treeData"
          :treeExpand="true"
          style="font-size: 13px"
          @getNodeClick="handleNodeClick"
        />
        <el-main>
          <!--                    <grid-head-layout-->
          <!--                      :searchSpan="2"-->
          <!--                      ref="searchForm"-->
          <!--                      :search-columns="searchColumns"-->
          <!--                      @grid-head-search="searchChange"-->
          <!--                      @grid-head-empty="searchReset"-->
          <!--                    />-->
          <div class="searchTopBox">
            <div class="leftSearch">
              <div style="width: 100%; display: flex; margin-bottom: 6px">
                <el-input
                  v-model="searchForm.riskName"
                  size="mini"
                  placeholder="请输入风险名称"
                  class="searchBox"
                ></el-input>
                <el-input
                  v-model="searchForm.treatmentTypeName"
                  size="mini"
                  placeholder="请输入措施分类"
                  class="searchBox"
                ></el-input>
                <el-input
                  v-model="searchForm.treatmentDesc"
                  size="mini"
                  placeholder="请输入措施描述"
                  class="searchBox"
                ></el-input>
                <el-input
                  v-model="searchForm.hdCheckContext"
                  size="mini"
                  placeholder="请输入隐患排查内容"
                  class="searchBox"
                ></el-input>
                <el-select
                  clearable
                  v-model="searchForm.controlLevel"
                  placeholder="请输入管控层级"
                  size="mini"
                  class="searchBox"
                >
                  <el-option
                    v-for="item in riskControlLevel"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-model="searchForm.respUserName"
                  size="mini"
                  placeholder="请输入负责人员"
                  class="searchBox"
                ></el-input>
                <div class="rightSearch">
                  <el-button-group>
                    <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-search"
                      @click="searchChange"
                    ></el-button>
                    <el-button
                      size="mini"
                      icon="reset-refresh icon-refresh"
                      @click="searchReset"
                      style="height: 28px"
                    ></el-button>
                  </el-button-group>
                </div>
              </div>
            </div>
          </div>
          <grid-layout
            ref="gridLayOutfxy"
            :table-options="fxyOption"
            :data-total="tableTotal"
            :table-data="fxyTableData"
            :table-loading="fxyTableLoading"
            :page="fxyPage"
            @gird-handle-select-click="selectionChange"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
          >
          </grid-layout>
        </el-main>
      </el-container>
      <div class="avue-dialog__footer">
        <el-button @click="fxyModel = false">取 消</el-button>
        <el-button @click="handleFxySave" type="primary">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <fileView ref="fileView" title="附件预览"></fileView>
    <el-dialog
      title="检查项查看"
      v-dialogDrag
      v-if="zxhcModel"
      :visible.sync="zxhcModel"
      class="avue-dialog avue-dialog--top"
      width="60%"
    >
      <el-form
        ref="modelFormDataForm"
        :model="modelForm"
        label-width="150px"
        :disabled="modelForm.disabled"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="检查结果" prop="lineStatus">
              <el-radio v-model="modelForm.lineStatus" label="NORMAL"
                >正常</el-radio
              >
              <el-radio v-model="modelForm.lineStatus" label="ERROR"
                >异常</el-radio
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检查地点" prop="prjName">
              <el-input
                v-model="modelForm.position"
                placeholder="请填写检查地点"
              >
                <template slot="append">
                  <div
                    class="el-icon-map-location"
                    style="width: 54px; text-align: center"
                  ></div>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="检查内容" prop="indexName">
              <el-input
                v-model="modelForm.indexName"
                type="textarea"
                :autosize="{ minRows: 2 }"
                maxlength="500"
                show-word-limit
                placeholder="请输入检查内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="隐患编号" prop="hdCode">
              <el-input
                v-model="modelForm.hdCode"
                disabled
                placeholder="隐患编号自动生成"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="隐患描述" prop="hdDesc">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2 }"
                show-word-limit
                v-model="modelForm.hdDesc"
                maxlength="225"
                placeholder="请填写隐患描述"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="隐患情况" prop="prjAs">
              <div class="content">
                <div
                  v-for="(item, index) in ModelTreeData"
                  :key="item.id"
                  class="img-content"
                  @click="handlePreview(item)"
                >
                  <img
                    v-if="
                      item.extension == 'jpg' ||
                      item.extension == 'png' ||
                      item.extension == 'image/jpeg' ||
                      item.extension == 'image/png'
                    "
                    :src="item.link"
                    alt=""
                  />
                  <img
                    v-else
                    src="@/assets/images/dashboard-nodata.png"
                    alt=""
                  />
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="avue-dialog__footer">
        <el-button @click="zxhcModel = false">取 消</el-button>
      </div>
    </el-dialog>
    <WorkTicket
      ref="WorkTicket"
      v-if="WorkTicketShow"
      @selectWorkTicket="selectWorkTicket"
    ></WorkTicket>
    <riskArea ref="riskArea" multiple @callback="selectWorkTicket"></riskArea>
    <equipmentDialog ref="equipmentDialog" @callback="selectWorkTicket" />
    <el-dialog
      top="9vh"
      title="知识库选择"
      :visible.sync="showKnowledgeSearchDialog"
      width="80%"
    >
      <KnowledgeSearchDialog
        ref="knowledgeSearchDialog"
        @close-dialog="closeKnowledgeSearchDialog"
        @select-data="selectFile"
      ></KnowledgeSearchDialog>
    </el-dialog>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import mixins from "./mixins";

import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import { getToken } from "@/util/auth";
import { exportBlob } from "@/api/common";
import { downloadXls } from "@/util/util";
import { dateNow } from "@/util/date";
import { dictionaryBiz } from "@/api/reportTasks";
import { mapGetters } from "vuex";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog.vue";
import * as API from "@/api/check/daily";
import {
  hdchecktasklineDetail,
  hdchecktasklineList,
} from "@/api/check/checkTasks";
import * as project from "@/api/riskManage/project";
import { hdchecktaskproPage } from "@/api/check/daily";
import website from "@/config/website";
import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog.vue";
import WorkTicket from "@/components/WorkTicket/index.vue";
import riskArea from "@/views/hiddenTrouble/check/daily/region.vue";
import snows from "@/util/snows";
import Template from "@/views/message/template/list.vue";
import KnowledgeSearchDialog from "@/views/components/knowledge/KnowledgeSearchDialog.vue";
import equipmentDialog from "@/views/equipmentFacilities/equipmentMaintenance/compoment/equipmentDialog.vue";

export default {
  mixins: [mixins],
  components: {
    equipmentDialog,
    Template,
    DeptDialog,
    UserDetpDialog,
    UserDeptMultipleDialog,
    WorkTicket,
    riskArea,
    CommonTree,
    GridLayout,
    KnowledgeSearchDialog,
    HeadLayout,
  },
  data() {
    return {
      respUserList: [],
      defaultExpandedKeys: [],
      dialogType: "",
      showKnowledgeSearchDialog: false,
      searchForm: {},
      fileUrl: "",
      modelForm: {},
      zxhcModel: false,
      modelType: false,
      WorkTicketShow: false,
      MultipShow: false,
      showFileDialog: false,
      tableTotal: 0,
      isSource: false,
      fxyTableLoading: false,
      hdchecktaskproData: [],
      ModelTreeData: [],
      treeData: [],
      fxyTableData: [],
      nowTreeNode: {},
      corePersonnelTreeData: [],
      jcxForm: {
        indexTypeId: "",
        indexTypeName: "",
        respUserName: "",
        indexName: "",
      },
      fxyPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      jcxflForm: {
        parentId: -1,
        objectName: "",
        typeName: "",
        isObject: 0,
      },
      selectTree: undefined,
      check_type: [],
      riskControlLevel: [],
      punishmentList: [],
      RectifyList: [],
      xgzlFileList: [],
      userList: [],
      fxyModel: false,
      deptShow: false,
      jcxModel: false,
      jcxflModel: false,
      deptShow1: false,
      type: "",
      hideUpload: false,
      excelBox: false,
      lineStatusColor: {
        PREPARE: "rgb(56, 148, 255)",
        NORMAL: "rgb(122, 199, 86)",
        ERROR: "rgb(242, 97, 97)",
      },
    };
  },
  computed: {
    pageDisabled() {
      // 如果查看 或者 提交状态则禁用
      if (this.formType == "view" || this.dataForm.actStatus == "PREPARE") {
        return true;
      } else {
        return false;
      }
    },
    headTitle() {
      if (this.check_type.length == 0) return "";
      if (!this.dataForm.checkType) return "";
      let { dictValue } = this.check_type.filter(
        (item) => item.dictKey == this.dataForm.checkType
      )[0];
      return dictValue;
    },
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
    ...mapGetters(["userInfo"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    userIds() {
      let ids = [];
      this.userList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  async mounted() {
    let type = this.$route.query.type;
    this.formType = type;
    if (type == "edit") {
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    } else if (type == "view") {
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    } else {
      this.$set(this.dataForm, "organizationId", this.userInfo.dept_id);
      this.$set(this.dataForm, "organizationName", this.userInfo.deptName);
      this.$set(this.dataForm, "organizationId", this.userInfo.dept_id);
      this.$set(this.dataForm, "organizationName", this.userInfo.deptName);
      this.$set(this.dataForm, "startUserId", this.userInfo.user_id);
      this.$set(this.dataForm, "startUserName", this.userInfo.real_name);
      this.$set(this.dataForm, "actStatus", "PREPARE");
      this.getPageCode();
      this.$set(
        this.dataForm,
        "checkType",
        String(this.$route.query.checkType)
      );
    }
    dictionaryBiz("check_type").then((res) => {
      this.check_type = res.data.data.filter((item) => item.dictKey != "7");
    });
    dictionaryBiz("risk_control_level").then((res) => {
      this.riskControlLevel = res.data.data;
    });
  },
  methods: {
    closeKnowledgeSearchDialog() {
      this.showKnowledgeSearchDialog = false;
    },
    handleTable(type) {
      this.modelType = type;
      if (type == "ts") {
        this.WorkTicketShow = true;
        this.$nextTick(() => {
          this.$refs.WorkTicket.closeDialog();
        });
      } else if (type == "qy") {
        this.$refs.riskArea.init();
      } else if (type == "EQ") {
        this.$refs.equipmentDialog.init();
      }
    },

    selectWorkTicket(data) {
      let newData = !Array.isArray(data) ? [data] : data;
      let saveList = newData.map((item) => {
        let objectName = "";
        if (item.type) {
          objectName = `${item.name}-${item.workContent.substring(0, 10)}`;
        } else {
          objectName = item.name || item.areaName || item.equipmentName;
        }
        return {
          // ...item,
          targetId: this.modelType == "EQ" ? item.id : "",
          objectCode: this.modelType == "EQ" ? item.equipmentCode : "",
          objectType: this.jcxflForm.objectType,
          typeName: objectName,
          parentId: this.jcxflForm.parentId || -1,
          typeId: this.jcxflForm.parentId || -1,
          actId: this.dataForm.id,
          stTypeId: this.modelType == "EQ" ? item.standardTypeId : "",
          areaCode: this.modelType == "qy" ? item.areaCode : "",
        };
      });
      API.hdcheckindexSaveList(saveList).then((res) => {
        this.jcxflModel = false;
        this.$message.success(res.data.msg);
        this.typeTree();
      });
    },
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    async zxjc(row) {
      let lineStatus = row.lineStatus == "PREPARE" ? "" : row.lineStatus;
      await hdchecktasklineDetail({ id: row.id }).then((res) => {
        let data = res.data.data;
        this.modelForm = {
          ...data,
          yhID: data.hdHiddenDanger.id,
          ...data.hdHiddenDanger,
          disabled: true,
          lineStatus: lineStatus || "NORMAL",
        };
        this.ModelTreeData = data.hdHiddenDanger.hdUrl
          ? JSON.parse(data.hdHiddenDanger.hdUrl)
          : [];
        this.zxhcModel = true;
      });
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property == "lineStatus") {
        return {
          color: this.lineStatusColor[row.lineStatus],
        };
      }
    },
    async getPageCode() {
      let code = await API.getCode().then((res) => res.data.data);
      this.$set(this.dataForm, "actCode", code);
    },
    handleSwitch() {
      if (this.dataForm.isAutomatic) {
        this.getPageCode();
      } else {
        this.dataForm.actCode = "";
      }
    },
    quote() {
      if (this.dataForm.id) {
        this.showKnowledgeSearchDialog = true;
      } else {
        this.$message({
          message: "基础信息未保存,请先保存基础信息",
          type: "warning",
        });
      }
    },
    rowView(row) {
      this.$router.push({
        path: `/hiddenTrouble/riskitemView/checklist/edit`,
        query: {
          id: row.id,
          type: "view",
        },
      });
    },
    handleFxySave() {
      let selectionList = this.selectionList.map((item) => {
        return {
          ...item,
          id: "",
          indexName: item.hdCheckContext,
          objectId: this.selectTree.id,
          objectName: this.selectTree.typeName,
          actId: this.dataForm.id,
        };
      });
      API.hdcheckindexsaveList(selectionList).then((res) => {
        this.fxyModel = false;
        this.selectionList = [];
        this.$message.success(res.data.msg);
        this.getJcx();
      });
    },
    headFx() {
      if (!this.selectTree) return this.$message.success("请选择检查项对象");
      this.fxyModel = true;
      this.searchReset();
      this.getdemotree();
    },
    getNodeId(clo, id) {
      console.log(clo, id, 9999);
      if (id) {
        const result = this.treeArrData.find((item) => item.id == id);
        console.log(result, this.treeArrData, id, "getNodeId0000");
        this.isSource = result.isSource;
        this.nowTreeNode = result || {};
      } else if (clo && clo.value) {
        const result = this.treeArrData.find((item) => item.id == clo.value);
        this.isSource = result.isSource || false;
        this.nowTreeNode = result || {};
      }
    },
    searchReset() {
      this.searchForm = {};
      this.fxyPage.currentPage = 1;
      this.searchChange();
    },
    searchChange() {
      let params = {
        ...this.searchForm,
        size: this.fxyPage.pageSize,
        current: this.fxyPage.currentPage,
        organizationId: this.dataForm.organizationId,
      };
      if (this.isSource) {
        params.sourceId = this.nowNodeId;
      } else {
        params.sourceTypeId = this.nowNodeId;
      }
      // 调用列表接口;
      this.fxyTableLoading = true;
      API.pmriskTreatment(params)
        .then((res) => {
          console.log(res);
          this.fxyTableData = res.data.data.records;
          this.tableTotal = res.data.data.total;
          this.fxyTableLoading = false;
        })
        .catch((error) => {
          this.fxyTableLoading = false;
        });
    },
    handleSizeChange(e) {
      this.fxyPage.pageSize = e.pageSize;
      this.searchChange();
    },
    handleCurrentChange(e) {
      this.fxyPage.currentPage = e.currentPage;
      this.searchChange();
    },
    //点击树节点显示列表
    handleNodeClick(data) {
      this.nowNodeId = data.id;
      this.getNodeId({}, this.nowNodeId);
      this.fxyPage.currentPage = 1;
      this.searchChange();
    },
    //扁平化 树
    flattenTree(tree, parent = null) {
      let result = [];
      for (let node of tree) {
        let flatNode = { ...node, parent };
        result.push(flatNode);
        if (node.children && node.children.length) {
          result = result.concat(this.flattenTree(node.children, flatNode));
        }
        delete flatNode.children;
      }
      return result;
    },
    //树
    getdemotree() {
      project
        .getTree({ organizationId: this.dataForm.organizationId })
        .then((res) => {
          if (res.status == 200) {
            this.treeData = res.data.data;
            this.treeArrData = this.flattenTree(res.data.data);
          }
        });
    },
    typeTree() {
      API.getTreeTypes({
        actId: this.dataForm.id,
      }).then((res) => {
        this.corePersonnelTreeData = res.data.data;
        if (res.data.data.length != 0) {
          this.selectTree = res.data.data[0]?.children[0] || {};
          if (!this.selectTree.id) return;
          this.defaultExpandedKeys = [this.selectTree.id];
          this.$nextTick(() => {
            this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
              this.selectTree.id
            );
            this.$refs.commonTreeCore.nodeData = this.selectTree;
            this.corePersonnelTreeNodeClick(this.selectTree);
          });
        }
      });
    },
    resetChange() {
      this.jcxflModel = false;
      this.jcxflForm = {};
    },
    resetChange1() {
      this.jcxModel = false;
      this.jcxForm = {};
    },
    handleSubmit1() {
      API.hdcheckindexSubmit({
        ...this.jcxForm,
        objectId: this.selectTree.id,
        objectName: this.selectTree.typeName,
        actId: this.dataForm.id,
      }).then((res) => {
        this.jcxModel = false;
        this.$message.success(res.data.msg);
        this.getJcx();
        this.jcxForm = {};
      });
    },
    handleSubmit(form, done) {
      let data = {
        ...this.jcxflForm,
        parentId: this.jcxflForm.parentId || -1,
        typeId: this.jcxflForm.parentId || -1,
        actId: this.dataForm.id,
      };
      let url = API.hdcheckindextype;
      // this.jcxflForm.isObj == 0 ? API.hdcheckindextype : API.hdcheckindexObj;
      url(data)
        .then((res) => {
          this.jcxflModel = false;
          this.$message.success(res.data.msg);
          this.typeTree();
        })
        .catch((err) => {
          done();
        });
    },
    getJcx(page) {
      this.page = page || this.page;
      this.jcxTableLoading = true;
      let urlFun =
        this.dataForm.actStatus == "FINISHED" && this.formType == "view"
          ? hdchecktasklineList
          : API.hdcheckindexList;
      let obj = {
        objectId: this.selectTree.id,
      };

      urlFun({
        current: this.page.currentPage,
        size: this.page.pageSize,
        actId: this.dataForm.id,
        ...obj,
      }).then((res) => {
        this.page.total = res.data.data.total;
        this.$refs.jcxGridLayOut.page.total = this.page.total;
        this.corePersonnelTableData = res.data.data.records;
        this.jcxTableLoading = false;
      });
    },
    async addjcx(row = {}) {
      if (!this.selectTree)
        return this.$message.success("请选择检查项分类或对象");
      this.jcxForm = {
        ...row,
        objectId: this.selectTree.id,
        objectName: this.selectTree.typeName,
      };
      this.jcxModel = true;
      this.$forceUpdate();
    },
    //导入
    handleImport() {
      if (!this.dataForm.id) {
        return this.$message({
          message: "基础信息未保存,请先保存基础信息",
          type: "warning",
        });
      }
      // if (!this.selectTree?.isObj)
      //   return this.$message.success("请选择检查项对象");
      this.excelBox = true;
      this.excelOption.excelForm.excelFile = [];
    },
    headExport() {
      if (!this.selectTree)
        return this.$message.success("请选择检查项分类或对象");
      exportBlob(
        `/api/sinoma-hse-prj//hdcheckindex/export?objectId=${this.selectTree.id}`
      ).then((res) => {
        downloadXls(res.data, this.selectTree.typeName);
      });
    },
    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.getJcx();
      this.excelOption.excelForm.excelFile = [];
      done();
    },
    handleTemplate() {
      exportBlob(
        `/api/sinoma-hse-prj/hdcheckindex/exportTemplate?actId=${this.dataForm.id}`
      ).then((res) => {
        downloadXls(res.data, "检查项导入模板" + ".xlsx");
      });
    },

    getTreeAdd(row) {
      if (!this.dataForm.id) {
        return this.$message({
          message: "基础信息未保存,请先保存基础信息",
          type: "warning",
        });
      }
      // if (row.isObj == 1)
      //   return this.$message.error("当前节点为检查对象节点，不可新增节点");
      this.jcxflForm = {
        parentId: row.id || -1,
        isObject: row.isObject || 0,
        objectType: "SD",
        parentName: row.typeName,
      };
      this.$set(this.jcxflForm, "isObject", row.isObject || 0);
      this.jcxflModel = true;
    },
    headDel() {
      this.rowDel(this.selectionList);
    },
    rowDel(data) {
      this.selectionList = data;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", this.ids);
          return API.hdcheckindexRemove(formData);
        })
        .then(() => {
          this.selectionList = [];
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
          this.getJcx();
        });
    },
    getTreeDelete(data) {
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", data.id);
          return API.TypeTreeRemove(formData);
          // return data.isObj == 0
          //   ? API.TypeTreeRemove(formData)
          //   : API.hdcheckindexObjRemove(formData);
        })
        .then(() => {
          this.$refs.commonTreeCore.nodeData = {};
          this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
          this.typeTree();
        });
    },
    getTreeEdit(row) {
      this.jcxflForm = {
        ...row,
        typeName: row.typeName,
        parentName: row.typeName,
      };
      console.log(this.jcxflForm);
      this.$set(this.jcxflForm, "isObject", row.isObject);
      this.jcxflModel = true;
    },
    async getFileList(list) {
      await API.changeOrPunish({ actId: this.dataForm.id }).then((res) => {
        if (list == "RectifyList") {
          if (res.data.data) {
            let RectifyList = res.data.data
              .filter((item) => item.assessmentUrl)
              .map((item) => {
                if (item.assessmentUrl) {
                  return JSON.parse(item.assessmentUrl);
                } else {
                  return [];
                }
              });
            this.RectifyList = RectifyList.flat();
          }
        } else {
          if (res.data.data) {
            let punishmentList = res.data.data
              .filter((item) => item.punishUrl)
              .map((item) => {
                if (item.punishUrl) {
                  return JSON.parse(item.punishUrl);
                } else {
                  return [];
                }
              });
            this.punishmentList = punishmentList.flat();
          }
        }
      });
    },
    async getJcUser(val) {
      console.log(val);
      this.jcryPage = val || this.jcryPage;
      this.jcryTableLoading = true;
      await API.hdcheckpersonList({
        actId: this.dataForm.id,
        current: this.jcryPage.currentPage,
        size: this.jcryPage.pageSize,
      }).then((res) => {
        this.thirdPartyOrganizationTableData = res.data.data.records;
        this.jcryPage.total = res.data.data.total;
        if (this.$refs.gridLayOut1) {
          this.$refs.gridLayOut1.page.total = res.data.data.total;
        }
        this.jcryTableLoading = false;
      });
    },
    handleClick() {
      this.selectionList = [];
      this.selectTree = undefined;
      if (!this.dataForm.id) return;
      if (this.activeName == "corePersonnel") {
        this.typeTree();
      } else if (this.activeName == "thirdPartyOrganization") {
        this.getJcUser();
      } else if (this.activeName == "thirdPartyPersonnel") {
        this.getFile();
      } else if (this.activeName == "taskList") {
        hdchecktaskproPage({
          actId: this.dataForm.id,
        }).then((res) => {
          this.hdchecktaskproData = res.data.data.records;
        });
      } else if (this.activeName == "Rectify") {
        this.getFileList("RectifyList");
      } else if (this.activeName == "punishment") {
        this.getFileList("punishmentList");
      }
    },
    selectData1(row) {
      this.deptShow1 = false;
      this.$set(this.dataForm, "organizationId", row.id);
      this.$set(this.dataForm, "organizationName", row.deptName);
      this.$set(this.dataForm, "deptCategory", row.deptCategory);
      console.log("进行输出人员-------------------", row);
    },
    selectData(row) {
      if (this.type == "startUserName") {
        this.$set(this.dataForm, "startUserId", row.id);
        this.$set(this.dataForm, "startUserName", row.realName);
      } else if (this.type == "respUserName") {
        this.$set(this.jcxForm, "respUserName", row.account);
        this.$set(this.jcxForm, "respNickName", row.realName);
      } else if (this.type == "jcry") {
        let newRow = row.selectAllData.map((item) => {
          return {
            ...item,
            deptId: row.treeDeptId,
            deptName: row.treeDeptName,
            userId: item.id,
            userName: item.account,
            id: "",
            actId: this.dataForm.id,
          };
        });
        API.hdcheckpersonSubmit(newRow).then((res) => {
          this.$message.success(res.data.msg);
          this.handleClick();
        });
      }
      this.deptShow = false;
      this.MultipShow = false;
    },
    handleProjectOPen() {
      this.deptShow1 = true;
    },
    headAdd(type) {
      this.type = type;
      this.deptShow = true;
    },
    headAdd1(type) {
      if (this.dataForm.id) {
        this.type = type;
        this.MultipShow = true;
      } else {
        this.$message({
          message: "基础信息未保存,请先保存基础信息",
          type: "warning",
        });
      }
    },
    headRemove() {
      this.rowUserDel(this.userList);
    },
    rowUserDel(data) {
      this.userList = data;
      if (this.userList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", this.userIds);
          return API.hdcheckpersonremove(formData);
        })
        .then(() => {
          this.userList = [];
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
          this.getJcUser();
        });
    },
    corePersonnelTreeNodeClick(row) {
      this.selectTree = row;
      this.getJcx();
    },
    headImport() {
      if (!this.dataForm.id) {
        return this.$message({
          message: "基础信息未保存,请先保存基础信息",
          type: "warning",
        });
      }
      // console.log(111);
      this.$refs["uploadRef"].$children[0].$refs.input.click();
    },
    //批量选择
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionUserChange(list) {
      this.userList = list;
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    saveData(actStatus, boolean, Fun) {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$loading();
          Fun({
            ...this.dataForm,
            actStatus: actStatus,
          })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: !boolean ? "保存成功" : "提交成功",
                  type: "success",
                });
                if (boolean) {
                  this.$loading().close();
                  this.$router.$avueRouter.closeTag();
                  this.$router.back();
                  return;
                } else {
                  this.dataForm.id = res.data.data;
                  this.getPrjbasicinfoDetail();
                }

                this.$loading().close();
              }
            })
            .catch((err) => {
              this.$loading().close();
            });
        }
      });
    },
    // 保存
    async headSave(boolean, actStatus) {
      let that = this;
      if (actStatus == "FINISHED") {
        let taskBool = await API.queryTask({ id: this.dataForm.id }).then(
          (res) => res.data.data
        );
        if (taskBool) {
          if (window.confirm("当前有任务未执行完，点击确认自动执行")) {
            that.saveData(actStatus, boolean, API.update);
          }
        } else {
          that.saveData(actStatus, boolean, API.update);
        }
      } else {
        this.saveData(actStatus, boolean, API.submit);
      }
    },
    // 根据ID查表单详情
    async getPrjbasicinfoDetail() {
      await API.detail({
        id: this.dataForm.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data;
        }
      });
      this.getJcUser();
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 删除上传文件
    handleRemove(file, fileList) {
      let formData = new FormData();
      formData.append("ids", file.id);
      API.hdcheckmaterialRemove(formData).then((res) => {
        this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
        this.getFile();
      });
    },
    addFile(list) {
      API.hdcheckmaterialSubmit(list).then((res) => {
        this.$message.success(res.data.msg);
        this.getFile();
      });
    },
    handlePreview1(file) {
      snows.downloadFile1(file.link, file.name);
    },
    handlePreview(item) {
      this.$refs.fileView.showFile(item.link);
    },
    getFile() {
      API.hdcheckmaterialList({
        actId: this.dataForm.id,
      }).then((res) => {
        this.xgzlFileList = res.data.data;
      });
    },
    selectFile(data) {
      let file = data.map((item) => {
        let file = item.attachList[0] || {};
        return {
          actId: this.dataForm.id,
          name: item.fileName,
          extension: item.extension || item.fileSuffixType,
          link: file.link || item.fileCover,
        };
      });
      this.addFile(file);
      this.showKnowledgeSearchDialog = false;
    },
    // 新增上传文件
    handleSuccess(response, file, fileList) {
      let { name, link, originalName } = response.data;
      this.addFile([
        {
          actId: this.dataForm.id,
          name: originalName,
          link: link,
          originalName: name,
        },
      ]);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .common_tree_handle {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.shuttleBackBox {
  ::v-deep .avue-crud .el-table {
    height: 400px !important;
    max-height: 400px !important;
  }
}

.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  height: 100%;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.shuttleBackBox {
  width: 100%;
  height: calc(100% - 160px);

  .main-box {
    height: calc(100% - 56px);
    overflow: scroll;
  }

  .el-tabs {
    width: 100%;
  }

  .upload-demo {
    background-color: #ffffff;
    padding-left: 10px;
  }

  .table-box {
    height: 100%;
    overflow: scroll;
    width: calc(100% - 280px);
  }
}

.dangerBox ::v-deep .el-col {
  margin-bottom: 8px !important;
}

.dangerBox ::v-deep .avue-crud .el-table {
  height: 380px !important;
  max-height: 380px !important;
}

.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}

.deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: flex;
}

.unitBox {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

.unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}

.searchTopBox {
  padding: 14px 12px;
  width: calc(100% - 24px);
  display: flex;
  justify-content: space-between;
}

.leftSearch {
  width: 100%;
}

.searchBox {
  width: calc(15% - 5px) !important;
  margin-right: 5px;
}
.content {
  display: flex;
  align-items: center;

  .img-content {
    width: 188px;
    height: 100px;
    padding: 3px;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 12px;

    .delete-icon {
      position: absolute;
      top: -6px;
      right: -6px;
      font-size: 16px;
      color: red;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
